import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {selectArrayRoles} from "../../../utils/select-array";
import {
    editChildUserReset,
    userChildEdit,
    userChildEditPut
} from "../../../store/users/user-child-edit-slice/userChildSlice";
import {useNavigate, useParams} from "react-router-dom";
import InputMask from "react-input-mask";
import Select from "react-select";
import selectStyle from "../../../utils/select-styles";
import {userEdit} from "../../../store/users/user-edit-slice/userEditSlice";
import swal from "sweetalert";

function EditUserChildPage() {
    const {usersEdit} = useSelector((state) => state.userChildEdit);
    // console.log(usersEdit)
    const navigate = useNavigate();
    const {id, child} = useParams();
    const dispatch = useDispatch();
    const [password, setPassword] = useState(true);
    const [confirmPassword, setConfirmPassword] = useState(true);
    // const [userDataEdit, setUserDataEdit] = useState({});
    const [childUserDataEdit, setChildUserDataEdit] = useState({});
    const roles = usersEdit !== undefined ? selectArrayRoles(usersEdit.roles) : "empty";
    // console.log(usersEdit)

    // console.log(id, child)
    useEffect(() => {
        if (usersEdit !== undefined) {
            setChildUserDataEdit({
                "child" :{
                        id: child.length !== 0 ? child : "",
                        name: usersEdit.length !== 0 ? usersEdit.child.name : "",
                        status: usersEdit.length !== 0 ? usersEdit.child.status : "",
                        email: usersEdit.length !== 0 ? usersEdit.child.email : "",
                        phone: usersEdit.length !== 0 ? usersEdit.child.phone  : "",
                        email_verified_at: null,
                        parent_id: id,
                        role:
                            usersEdit.length !== 0 && usersEdit.child.roles.length > 0
                                ? usersEdit.child.roles[0].name
                                : "",
                    },
                "title" : usersEdit.length !== 0 ? usersEdit.title : ""
                })
        }
    }, [usersEdit])

    useEffect(() => {
        dispatch(editChildUserReset());
        dispatch(userChildEdit({id: id, childId: child}))
    }, [dispatch])

    const handlePasswordVisible = () => {
        setPassword(!password);
    };

    const handleConfirmPasswordVisible = () => {
        setConfirmPassword(!confirmPassword);
    };

    // Функция для отправки формы
    const handleChange = (e) => {
        const {name, value, type, checked} = e.target ? e.target : e;
        let newValue = value;
        if (type === "checkbox") {
            newValue = checked ? 1 : 0;
        }
        setChildUserDataEdit(prevState => ({
            ...prevState,
            child: {
                ...prevState.child,
                [name]: newValue, // Update the specific name field in the child object
            },
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        swal({
            title: "Вы уверены что хотите обновить данные?",
            icon: "warning",
            buttons: ["Отменить", "Да"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(userChildEditPut({id: id, childId: child, data: childUserDataEdit})).then(
                    (response) => {
                        if (response.payload.message === "Сохранено.") {
                            swal("Пользователь успешно создан!", {
                                icon: "success",
                            }).then(() => {
                                dispatch(userChildEdit({id: id, childId: child}));
                                navigate("/users");
                            });
                        } else {
                            let errorMessage = "Ошибка при редактирование пользователя!";

                            if (response.payload && response.payload.errors) {
                                const errorMessages = Object.values(
                                    response.payload.errors
                                ).reduce((acc, val) => acc.concat(val), []);
                                errorMessage = errorMessages.join("\n");
                            }
                            swal(errorMessage, {
                                icon: "error",
                            });
                        }
                    }
                );
            }
        });
    };

    return (
        usersEdit && usersEdit.length !== 0 && (
            <form onSubmit={handleSubmit}>
                <div className="container page-head-wrap max-width p-0">
                    <div className="page-head">
                        <div className="page-head__top d-flex align-items-center">
                            <h1>{usersEdit.title} </h1>

                        </div>
                    </div>
                </div>

                <div className="container d-flex justify-content-center">
                    <div className="inner-page max-width">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="inner-page__item">
                                    <div className="row">
                                        <div className="col-12 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>Имя</span>
                                                <span className="invalid-feedback" role="alert">
                                                  <strong></strong>
                                                </span>
                                            </label>
                                            <input
                                                id="name"
                                                type="text"
                                                defaultValue={usersEdit.child.name}
                                                name="name"
                                                required
                                                placeholder="Не указан"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>E-Mail</span>

                                            </label>
                                            <InputMask
                                                maskChar="_"
                                                defaultValue={usersEdit.child.email}
                                                id="email"
                                                name="email"
                                                required
                                                onChange={handleChange}
                                                placeholder="example@example.com"
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>Тел</span>

                                            </label>
                                            <InputMask
                                                // mask="+7 (999) 999-99-99"
                                                maskChar="_"
                                                defaultValue={usersEdit.child.phone}
                                                id="phone"
                                                name="phone"
                                                required
                                                onChange={handleChange}
                                                placeholder="+7 (___) ___-__-__"
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>Пароль*</span>
                                                <span className="password">

                                                  <div
                                                      className={`password-control  ${
                                                          password ? "" : "view"
                                                      }`}
                                                      onClick={handlePasswordVisible}
                                                  ></div>
                                                </span>
                                            </label>
                                            <input
                                                type={password ? "password" : "text"}
                                                name="password"
                                                placeholder="Не указан"
                                                value={userEdit.password}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>Подтверждение пароля*</span>
                                                <span className="password">

                                                  <div
                                                      className={`password-control  ${
                                                          confirmPassword ? "" : "view"
                                                      }`}
                                                      onClick={handleConfirmPasswordVisible}
                                                  ></div>
                                                </span>
                                            </label>
                                            <input
                                                type={confirmPassword ? "password" : "text"}
                                                value={userEdit.password_confirmation}
                                                name="password_confirmation"
                                                placeholder="Не указан"
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="label_form">
                                                <span className={'label_text'}>Роль</span>

                                            </label>
                                            <Select
                                                defaultValue={{
                                                    value: usersEdit.child.roles[0].name,
                                                    label: usersEdit.child.roles[0].name,
                                                    name: "role",
                                                }}
                                                options={roles}
                                                styles={selectStyle}
                                                onChange={handleChange}
                                                id="status-selection"
                                            />
                                        </div>
                                        <div className="col-12 mt-3 d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <label className="switch-radio-wrap mt-11px">
                                                <span className={'status'}>Статус</span>
                                                <input
                                                    type="checkbox"
                                                    name="status"
                                                    onChange={handleChange}
                                                    defaultChecked={usersEdit.child.status ? true : false}
                                                />
                                                <span className="switcher-radio"></span>
                                                <span>Включен</span>
                                            </label>

                                        </div>
                                        <div className="col-12 mt-5 ml-auto d-flex justify-content-center gap-5 align-items-center form-group-input">
                                            <button type="submit" className="btn btn-white">
                                                Обновить
                                            </button>
                                        </div>




                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        )
    );
}

export default EditUserChildPage