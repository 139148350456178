import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    getMessagesFromChat,
    sendMessageAndCreateRoomChat,
    sendMessageToRoomChat
} from "../../../../../../store/chat/chatSlice";
import { db } from '../../../../../../firebase';
import ApplicationChatItem from "./application-chat-item/applicationChatItem";
import {applicationsSingle} from "../../../../../../store/applications/applications-single/applicationsSingleSlice";
import { ref, onValue } from 'firebase/database';
import firebase from "firebase/compat/app";
function App({messageContent}) {
    const { single } = useSelector((state) => state.applicationSingle);
    const dispatch = useDispatch()
    const [messages, setMessages] = useState([]);
    const [messageFromFB, setMessageFromFB] = useState({});
    const [error, setError] = useState(null);
    const chatEndRef = useRef(null);

    useEffect(() => {
        // const db = getDatabase();
        const messagesRef = ref(db, `myparkingdev/chat_messages/applications/${single.application?.id}`);
        // Listen for changes in the 'messages' node
        const unsubscribe = onValue(messagesRef, (snapshot) => {
            setMessageFromFB(snapshot.val());
            setError(null); // Clear any previous errors
        }, (error) => {
            // Handle any errors
            console.error('Error fetching messages:', error);
            setError(error.message); // Set error state
        });
        // Clean up listener when component unmounts
        return () => {
            unsubscribe();
        };
    }, []); // Empty dependency array to run only once on mount
    useEffect(()=>{
        // setMessages([messageFromFB, ...messages])
        //
        if (messageFromFB) {
            setMessages((prevNotifications) => {
                const allNotifications = [messageFromFB, ...prevNotifications];
                const uniqueNotifications = new Set();
                return allNotifications.filter(notification => {
                    if (!uniqueNotifications.has(notification.id)) {
                        uniqueNotifications.add(notification.id);
                        return true;
                    }
                    return false;
                });
            });
        }
    }, [messageFromFB])
    useEffect(()=>{
        if (single.application?.chat !== undefined) {
            const id = single.application?.id
            dispatch(applicationsSingle(id)).then((resp) => {
                setMessages(resp.payload.application.chat.messages)
            })
        }
    },[dispatch])
    useEffect(()=>{
        if (single.application?.chat !== undefined) {
            const applicationId = single.application?.id
            const id = single.application?.id
            // dispatch(getMessagesFromChat({applicationId}))
            dispatch(applicationsSingle(id))
            setMessages(single.application?.chat.messages)
            // в работе
            // готово
            // отмена
            // на соглас
        }
    },[])


    const scrollToBottom = () => {
        chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    return (
        <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
            <div className="oders-chat__list" >
                {/*<div className="chat__date">01.01.21</div>*/}
                <div ref={chatEndRef} />
                {messages.map((message, index) => (
                    <ApplicationChatItem
                        key={index}
                        orentation={index}
                        message={message}
                    />
                ))}

            </div>
        </div>
    );
}

export default App;