import { Routes, Route } from "react-router-dom";
import ApplicationCreate from "../../pages/application/create/create";
import ApplicationIndex from "../../pages/application/index";
import Login from "../../pages/login/login";
import PasswordReset from "../../pages/password-reset/password-reset";
import Register from "../../pages/register/register";
import PrivateRoutes from "../../utils/PrivateRoutes";
import axios from "axios";
import Header from "../header/header";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import UserIndex from "../../pages/users/index";
import UsersCreatePage from "../../pages/users/create/create";
import ApplicationEditPage from "../../pages/application/edit/edit";
import UserEditPage from "../../pages/users/edit/edit";
import ReportPartner from "../../pages/reports/partner/partner";
import ReportEmployee from "../../pages/reports/employee/employee";
import ReportPartnerAll from "../../pages/reports/partner-all/partner-all";
import { roles } from "../../utils/roles";
import IssueRequestCreate from "../../pages/issue-request/create/issue-request-create";

import LeagalIndex from "../../pages/legals/index";

import LegalCreatePage from "../../pages/legals/create/create";
import LegalEditPage from "../../pages/legals/edit/edit";

import ParkingsIndex from "../../pages/parkings/index";
import ParkingsCreatePage from "../../pages/parkings/create/create";
import ParkingEditPage from "../../pages/parkings/edit/edit";

import PartnersIndex from "../../pages/partners/index";
import PartnerCreatePage from "../../pages/partners/create/create";
import PartnerEditPage from "../../pages/partners/edit/edit";

import IssueRequestEdit from "../../pages/issue-request/edit/issue-request-edit";
import PartnerSearchPage from "../../pages/partners/search/search";
import PartnerUserCreatePage from "../../pages/partners/users/create/create";
import PartnerUserEditPage from "../../pages/partners/users/edit/edit";
import LegalShowPage from "../../pages/legals/show/show";
import EditUserChildPage from "../../pages/users/edit/editChildren";
import ModalOfferPartner from "../modal-offer-partner/modal-offer-parnter";
import Footer from "../footer/footer";
import OfferPage from "../../pages/offer/offer";
import Clientoffer from "../../pages/clientoffer/clientoffer";

import Logs from "../../pages/logs/logs";

import Guide from "../../pages/guide/guide";
import Coverage from "../../pages/coverage/coverage";
import Fence from "../../pages/fence/fence";
import NotFoundPage from "../../pages/404/404";
import Services from "../../pages/services/services";
import ServicesCategory from "../../pages/services/servicesCategory";
import Profile from "../../pages/profile/profile";
import Order from "../../pages/reports/order/order";
import MassImport from "../../pages/application/import/mass-import";

const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://old.myparking.info/api";
      break;
    case "development":
    default:
      url = "https://dev.myparking.info/api";
  }
    // url = "https://old.myparking.info/api";
    url = "https://dev.myparking.info/api";

  return url;
};

axios.defaults.baseURL = getBaseUrl();

axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.post["Accept"] = "application/json";

axios.defaults.withCredentials = true;

axios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem("web_vinz"));
  config.headers.Authorization = token ? `Bearer ${token.token}` : "";
  return config;
});

function App() {

  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/register" element={<Register />} />
        <Route
          element={
            <PrivateRoutes
              role={[
                roles.operator,
                roles.superAdmin,
                roles.admin,
                roles.manager,
                roles.partner,
                roles.parterOperator,
                roles.moderator,
                roles.contractor,
              ]}
            />
          }
        >
          {/* Application */}
          <Route path="/applications/create" element={<ApplicationCreate />} />
          <Route
            path="/applications/:id/edit"
            element={<ApplicationEditPage />}
          />
          <Route path="/applications" element={<ApplicationIndex />} />
          <Route path="/applications/:id" element={<ApplicationIndex />} />
          <Route
            path="/applications/dublicate"
            element={<ApplicationIndex />}
          />
          <Route
                path="/applications/import"
                element={<MassImport />}
          />
          {/*  */}

          {/* Home */}
          <Route path="/" element={<ApplicationIndex />} />
          {/*  */}

          {/* Issue-request */}
          <Route
            path="/applications/:id/issue-requests/create"
            element={<IssueRequestCreate />}
          />
          <Route
            path="/applications/issue-requests/:id/edit"
            element={<IssueRequestEdit />}
          />
          {/*  */}

          {/* Offer */}
          <Route path="/offer" element={<OfferPage />} />
          {/*  */}

          {/* Clientoffer */}
          <Route path="/clientoffer" element={<Clientoffer />} />
          {/*  */}
        </Route>

        <Route
          element={
            <PrivateRoutes
              role={[
                roles.superAdmin,
                roles.admin,
                roles.manager,
                roles.moderator,
                roles.partner,
                roles.contractor,
              ]}
            />
          }
        >
          {/* User */}
          <Route path="/users" element={<UserIndex />} />
          <Route path="/users/create" element={<UsersCreatePage />} />
          <Route path="/users/:id/edit" element={<UserEditPage />} />
          <Route
            path="/users/:id/children/:child/edit"
            element={<EditUserChildPage />}
          />
          {/*  */}

          {/* Выгрузки */}
          <Route path="/report-by-partner" element={<ReportPartner />} />
          <Route path="/report-by-employee" element={<ReportEmployee />} />
          <Route path="/report-all-partner" element={<ReportPartnerAll />} />
          <Route path='/report-order' element={<Order/>} />
          {/*  */}

          {/* Legals */}
          <Route path="/legals" element={<LeagalIndex />} />

          <Route path="/legals/create" element={<LegalCreatePage />} />
          <Route path="/legals/:id/edit" element={<LegalEditPage />} />
          <Route
            path="/legals/user/:user_id/legal/:id"
            element={<LegalShowPage />}
          />

          {/* Parkings */}
          <Route path="/parkings" element={<ParkingsIndex />} />
          <Route path="/parkings/create" element={<ParkingsCreatePage />} />
          <Route path="/parkings/:id/edit" element={<ParkingEditPage />} />
          <Route path="/guide/coverage" element={<Coverage />} />
          <Route path="/guide/fence" element={<Fence />} />
          <Route path="/guide/services" element={<Services />} />
          <Route path="/guide/services-category" element={<ServicesCategory/>} />

          {/* Partner */}

          <Route path="/partners" element={<PartnersIndex />} />
          <Route path="/partners/search" element={<PartnerSearchPage />} />
          <Route path="/partners/:id/edit" element={<PartnerEditPage />} />
          <Route path="/partners/add-new" element={<PartnerCreatePage />} />
          <Route path="/partner/add/:id" element={<PartnerCreatePage />} />

          {/* Partner-User */}
          <Route
            path="/partner-users/partner/:id/create"
            element={<PartnerUserCreatePage />}
          />
          <Route
            path="/partner-users/:user_id/partner/:id/edit"
            element={<PartnerUserEditPage />}
          />
          {/* Logs */}
          <Route path="/logs" element={<Logs />} />

        </Route>
        <Route
              element={
                  <PrivateRoutes
                      role={[
                          roles.superAdmin,
                      ]}
                  />
              }
        >
              {/* Guide */}
              <Route  path="/guide" element={<Guide />} />

        </Route>
          {/* profile */}
        <Route  path="/profile" element={<Profile />} />
        {/* 404 страница */}
        <Route path="*" element={<NotFoundPage />} />
        {/*  */}
      </Routes>
      <ToastContainer />
      <ModalOfferPartner />
      <Footer />
    </div>
  );
}

export default App;
