import {useDispatch} from "react-redux";
import {notificationData, notificationReade} from "../../../../../store/notofocations/headerNotificationSlice";

function HeaderNotificationItem(
    {
        item,
        openModal,
        setActiveTabKey,
        setNotifications
    }) {


    const dispatch = useDispatch()
    const handleClick = (applicationId, notification_id, type, orderID, e) => {
        e.preventDefault()
        const id = {
            notificationId:notification_id
        }
        dispatch(notificationReade(id)).then((resp)=>{
            if (resp.payload === 200){
                dispatch(notificationData()).then((resp) => {
                    // setNotifications(resp.payload?.notifications?.slice(0, 10))
                    setNotifications(resp.payload?.notifications)
                })
            }
        })
        switch (type){
            case 'order' :
                setActiveTabKey(
                    {
                        tabName : 'tabCurrentServices',
                        serviceId : applicationId,
                        applicationId : orderID
                    })
                openModal(applicationId, type='order', orderID)
                break
        }
    }
     return (
        item && item.type === 'application' ?  <li
            key={item.id}
            className={`${item.type } underline-none notif-item`}
        >
            <a href={item.type === 'application' ? `/applications/${item.application_id}/edit` : '#'} className="all-notif application">
                <div className={'notification__top'} >
                    <div className="notification__top-left-info">
                        {item.sender_email &&
                        <span> {item.sender_email} </span>
                        }
                        {item.sender_role &&
                        <span> ({item.sender_role}) </span>
                        }
                    </div>
                    <div className="notification__top-right-info">
                        {item.date &&
                        <span>{item.date}</span>
                        }
                    </div>
                </div>
                {item.short}
            </a>
        </li> :
            item && item.type === 'order' ? <li
            key={item.id}
            className={`${item.type } underline-none notif-item`}
            onClick={(e)=>{handleClick(item.application_id, item.notification_id,item.type, item.order_id, e)}}>
            <a href={'#'} className="all-notif order">
                <div className={'notification__top'} >
                    <div className="notification__top-left-info">
                        {item.sender_email &&
                        <span> {item.sender_email} </span>
                        }
                        {item.sender_role &&
                        <span> ({item.sender_role}) </span>
                        }
                    </div>
                    <div className="notification__top-right-info">
                        {item.date &&
                        <span>{item.date}</span>
                        }
                    </div>
                </div>
                {item.short}
            </a>
        </li> : ''

    )
}

export default HeaderNotificationItem;