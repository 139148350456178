import React, {useCallback, useRef, useState} from "react";
import MyModal from "../../../../modal/modal";

import ApplicationSliderImage from "../application-slider-image/applications-slider-image";
import ApplicationModalHeader from "./applications-modal-header/applications-modal-header";
import {Tabs, Tab, Nav, NavItem, NavDropdown} from "react-bootstrap";
import ApplicationsPhoto from "./applications-photo/applications-photo";
import ApplicationsDocument from "./applications-document/applications-document";
import { useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import ApplicaitonFooterModerator from "./applicaitons-footer/applications-footer-moderator/applications-footer-moderator";
import ApplicationsFooterPhotoDownload from "./applicaitons-footer/applications-footer-photo-download/applications-footer-photo-download";
import ApplicationsEditButton from "../applications-item/applications-item-button/applications-edit-button/applications-edit-button";
import ApplicationDeleteButton from "../applications-item/applications-item-button/application-delete-button/application-delete-button";
import ApplicationGenereteAct from "../applications-item/applications-item-button/applications-generete-act/applications-generete-act";
import ApplicationsTS from "./applications-ts/application-ts";
import ApplicationFooterIssueBtn from "./applicaitons-footer/applicatons-footer-issue/applications-footer-isuue";
import ApplicationAcceptBtn from "./applicaitons-footer/applications-footer-accept/applications-footer-accept";
import ApplicationFooterDeny from "./applicaitons-footer/applications-footer-deny/applications-footer-deny";
import AplicationServices from "./aplication-services/aplication-services";
import ApplicationFooterService from "./applicaitons-footer/application-footer-service/application-footer-service";
import {
  applicationServicesAddToOrder, applicationServicesDeleteOrder,
  applicationServiceSend,
  applicationServicesGetById, currentApplicationServiceById
} from "../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {getAllFence} from "../../../../../store/parkings/create-fence/createFenceSlice";
import swal from "sweetalert";
import item from "../../../../../pages/reports/partner-all/item/item";
import AplicationServicesItem from "./aplication-services/aplication-services-item";
import {applicationsAll} from "../../../../../store/applications/applicationsSlice";
import {applicationServicesCancelById} from "../../../../../store/applications/application-service-order-cancel/applicationServiceOrderCancelSlice";
import {
  applicationServiceApproveManager,
  toggleServiceComplete
} from "../../../../../store/applications/application-service-order-manger-approve/applicationServiceOrderMangerApproveSlice";
import {applicationServiceApprovePartner} from "../../../../../store/applications/application-service-order-partner-approve/applicationServiceOrderPartnerApproveSlice";
import ApplicationChat from "./application-chat/applicationChat";
import ApplicationItemServicesModal from "../application-item-services-modal/application-item-services-modal";
import {applicationsSingle} from "../../../../../store/applications/applications-single/applicationsSingleSlice";
import ApplicationFooterChat from "./applicaitons-footer/application-footer-chat/application-footer-chat";
import {
  getMessagesFromChat,
  sendMessageAndCreateRoomChat,
  sendMessageToRoomChat
} from "../../../../../store/chat/chatSlice";
import ApplicationLogs from "./application-logs/application-logs";
import ApplicationServiceList from "./aplication-services/application-service-list";
import { db } from '../../../../../firebase';
import { ref, onValue } from 'firebase/database';
function ApplicationItemModal({
  single,
  isModalOpen,
  closeModal,
  setFavorite,
  openServicesModal,
  isServiceModalOpen,
  closeServicesModal,
  tab,
}) {
  const [key, setKey] = useState("tab1");
  // const {single} = useSelector((state) => state.applicationSingle);
  const [dataQuickEdit, setDataQuickEdit] = useState({});
  const { user } = useSelector((state) => state.auth);
  // const services  = useSelector((state) => state);
  const currentServices  = useSelector((state) => state.applicationService);
  const [dataAboutSelectedServices, setDataAboutSelectedServices] = useState({})
  const [currentApplicationId, setCurrentApplicationId] = useState(0)
  const dispatch = useDispatch();

  const [showFooterForOrders, setShowFooterForOrders] = useState(false);
  const [showFooterForChat, setShowFooterForChat] = useState(false);
  const [approvedPrice, setApprovedPrice] = useState({
    orderServices: []
  })
  const [orderServices, setOrderServices]= useState({ orderServices: [] })
  const [servicesForFooter, setServicesForFooter] = useState([]);
  const [updateAPP, setUpdateAPP] = useState([false]);

  const [showSubOrders, setShowSubOrders] = useState(true);
  const [currentApplicationOrderId, setCurrentApplicationOrderId] = useState(null);
  const [messageContent, setMessageContent] = useState('');
  const [model, setModel] = useState('application');


  const [allOrdersFromOurApi, setAllOrdersFromOurApi] = useState([]);

  useEffect(()=>{
    setAllOrdersFromOurApi(currentServices?.services?.orders)
  },[currentServices])

  useEffect(()=>{
    if (currentServices.currentService.orderServices) {
      const tempData = currentServices.currentService.orderServices
      const filteredData = tempData.map(({ id, approved_price, price_for_us }) => ({
        id,
        price : approved_price,
        price_for_us
      }));
      setApprovedPrice({
        orderServices: filteredData
      });
    }
    if (currentServices?.currentService?.length > 0) {
      // const data = {
      //   parking_id: parkingId,
      //   orderServices:selectedServices
      // }
    }

  },[currentServices.currentService])
  useEffect(() => {
    setDataQuickEdit({
      appid: single.application?.id,
    });
  }, [single]);
  const contentRef = useRef(null);
  //открываем нужный таб
  const handleSelect = useCallback((k) => {
    setKey(k);
    switch (k){
      case 'tabCurrentServices' :
        setShowFooterForOrders(true)
        setShowFooterForChat(false)
        break
      case 'tabServices' :
        setShowFooterForOrders(true)
        setShowFooterForChat(false)
        break
      case 'openSideBar' :
        setShowFooterForOrders(false)
        setShowFooterForChat(false)
        openSideBarOrders()
        setKey('serviceList')
        break
      case 'chat' :
        setShowFooterForOrders(false)
        setShowFooterForChat(true)
        const applicationId = single.application?.id
        dispatch(getMessagesFromChat({applicationId})).then((res)=>{
        })
        // contentRef.current.scrollTop = contentRef.current.scrollHeight;
        scrollToBottom()
        break
      default :
        setShowFooterForOrders(false)
        setShowFooterForChat(false)
    }
  },[])
  // useEffect(()=>{
  //   if (key === 'chat'){
      // contentRef.current.scrollTop = contentRef.current.scrollHeight;
      // scrollToBottom()
    // }
  // },[])

  // Function to scroll to the bottom of the chat container
  const scrollToBottom = () => {
    const chatContainer = document.getElementById('modal-block__main');
    // chatContainer.scrollTop = chatContainer.scrollHeight;
  };

  // открываем/закрываем таб с историей заказов
  const openSideBarOrders = () =>{
    setShowSubOrders(!showSubOrders);
    dispatch(applicationServicesGetById({currentApplicationId})).then((response)=>{
    })
  }
  useEffect(()=>{
    dispatch(applicationServicesGetById({currentApplicationId}))
  },[currentApplicationId])
  useEffect(()=>{
    setCurrentApplicationId(single.application?.id)
  },[single.application])

  // открываем конкретный заказ
  const openCurrentOrders = useCallback(
      (orderID, applicationId) => {
        setKey('tabCurrentServices');
        dispatch(currentApplicationServiceById({applicationId, orderID})).then((response)=>{
        })
        setCurrentApplicationOrderId(orderID)
      }
      ,[])

 useEffect(()=>{
   if (tab !== undefined && tab !== null){
     handleSelect(tab.tabName)
     if (tab.tabName === 'tabCurrentServices'){
       openCurrentOrders(tab.applicationId, tab.serviceId )
     }
   }
 },[tab])

  // собираем все выбранные сервисы для оформления заказа партнером
  const getApplicationSelectedServices = (appId,parkingId, selectedServices)=>{
    const data = {
      parking_id: parkingId,
      orderServices:selectedServices
    }
    setCurrentApplicationId(appId)
    setDataAboutSelectedServices(data)
  }

  // отправляем данные о выбранных услугах в футер, что бы показать их список там
  const getSelectedServicesForFooterInfo = (servisec) =>{
    setServicesForFooter(servisec)
  }

  //Партнет или менеджер создал заказ
  const sendApplicationSelectedServices = () => {
    dataAboutSelectedServices.orderServices.forEach(service => {
      delete service.name;
      delete service.price;
      delete service.price_for_us;
    });
    const data = dataAboutSelectedServices
    dispatch(applicationServiceSend({currentApplicationId, data})).then((response)=>{
      if(response.payload === 201 || response.payload === 200 ){
        swal("Заказ отправлен на согласование", {
          icon: "success",
        }).then(() => {
          setKey('tabServices')
          dispatch(applicationServicesGetById({currentApplicationId}))
        });
      }else{
        swal("Ошибка при отправке заказа", {
          icon: "error",
        }).then(() => {
          setKey('tabServices')
        });
      }
    })
  }

  const modifyApprovedPrice = (approvedPrice) => {
    // Check if approvedPrice is an object and has the "orderServices" field
    if (approvedPrice && typeof approvedPrice === 'object' && Array.isArray(approvedPrice.orderServices)) {
      // Map through the orderServices array and rename the "approved_price" field to "price"
      const modifiedOrderServices = approvedPrice.orderServices.map(service => {
        const { approved_price, ...rest } = service;
        return { ...rest, price: approved_price };
      });
      return { ...approvedPrice, orderServices: modifiedOrderServices };
    }
    return approvedPrice;
  };

  //Менеджер сразу отправяет в работу имеющийся заказ
  const sendOrderToWork = () => {
    swal({
      title: "Вы уверены что хотите отправить заказ сразу в работу?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willGo) => {
      if (willGo){
        approvedPrice.start = true
        const  data = {
          ...approvedPrice,
          orderServices: approvedPrice.orderServices.map(service => ({
            ...service,
            price: service.price === null ? '0' : service.price,
            price_for_us: service.price_for_us === null ? '0' : service.price_for_us
          }))
        };
        dispatch(applicationServiceApproveManager({currentApplicationId, currentApplicationOrderId, data}))
            .then((response) => {
              if (response.meta.requestStatus === "fulfilled") {
                const orderID = currentApplicationOrderId;
                dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
                swal("Заказ отправлен в работу", {
                  icon: "success",
                }).then(() => {
                  setKey('tabCurrentServices')
                  dispatch(applicationServicesGetById({currentApplicationId}))
                });
              } else {
                swal("Ошибка при отправки заказа", {
                  icon: "error",
                }).then(() => {
                  setKey('tabCurrentServices')
                });
              }
            })
      }
      // setKey('tabServices')
      dispatch(applicationServicesGetById({currentApplicationId}))
    });
  }

  //Менеджер сразу отправяет в работу при создании заказа
  const sendOrderToWorkFromCreatingOrder = () => {
    swal({
      title: "Вы уверены что хотите отправить заказ сразу в работу?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willGo) => {
      if (willGo){
        dataAboutSelectedServices.orderServices.forEach(service => {
          delete service.name;
        });
        dataAboutSelectedServices.start = true
        const  data = {
          ...dataAboutSelectedServices,
          orderServices: dataAboutSelectedServices.orderServices.map(service => ({
            ...service,
            price: service.price === null ? '0' : service.price,
            price_for_us: service.price_for_us === null ? '0' : service.price_for_us
          }))
        };
        setDataAboutSelectedServices(data)

        dispatch(applicationServiceSend({currentApplicationId, data})).then((response)=>{
          if(response.payload === 201 || response.payload === 200 ){
            swal("Успешно!", {
              icon: "success",
            })
          }else{
            swal("Ошибка при отправке заказа", {
              icon: "error",
            }).then(() => {
              setKey('tabServices')
            });
          }
        })
      }
      setKey('tabServices')
      dispatch(applicationServicesGetById({currentApplicationId}))
    });
  }

  //Менеджер согласует заказ
  const approveServiceByManager = () => {
    const  data = {
      ...approvedPrice,
      orderServices: approvedPrice.orderServices.map(service => ({
        ...service,
        price: service.price === null ? '0' : service.price,
        price_for_us: service.price_for_us === null ? '0' : service.price_for_us
      }))
    };
    dispatch(applicationServiceApproveManager({currentApplicationId, currentApplicationOrderId, data}))
        .then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            const orderID = currentApplicationOrderId;
            dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
            swal("Заказ отправлен на согласование", {
              icon: "success",
            }).then(() => {
             setKey('tabCurrentServices')
              dispatch(applicationServicesGetById({currentApplicationId}))
            });
          } else {
            swal("Ошибка при отправки заказа", {
              icon: "error",
            }).then(() => {
              setKey('tabCurrentServices')
            });
          }
        })
  }
  //Партнер подтверждает заказ
  const approveServiceByPartner = () =>{
    dispatch(applicationServiceApprovePartner({currentApplicationId,currentApplicationOrderId,orderServices})).then((response)=>{
      if (response.meta.requestStatus === "fulfilled"){
        const orderID = currentApplicationOrderId;
        dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
        swal("Заказ согласован", {
          icon: "success",
        })
      }else {
        swal("Ошибка при согласовании заказа", {
          icon: "error",
        })
      }
    })
  }

  const toggleCompleteApplicationService = (currentApplicationOrderServiceId)=>{
    dispatch(toggleServiceComplete({currentApplicationId,currentApplicationOrderId,currentApplicationOrderServiceId})).then((response)=>{
      if (response.meta.requestStatus === "fulfilled"){
        const orderID = currentApplicationOrderId;
        dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
        dispatch(applicationServicesGetById({currentApplicationId}))
        setUpdateAPP(!updateAPP)
        swal("Операция выполнена", {
          icon: "success",
        },)
      }else {
        swal("Ошибка при выполнении операции", {
          icon: "error",
        })
      }

    })
  }
  //Отмена заказа
  const cancelServiceById = () => {
    dispatch(applicationServicesCancelById({currentApplicationId,currentApplicationOrderId})).then((response)=>{
      if (response.meta.requestStatus === "fulfilled"){
        const orderID = currentApplicationOrderId;
        dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
        dispatch(applicationServicesGetById({currentApplicationId}))
        swal("Заказ отменен", {
          icon: "success",
        })
      }else {
        swal("Ошибка при отмене заказа", {
          icon: "error",
        })
      }
    })
  }

  //Добавление услуги в заказ
  const handleAddServiceToOrder = (data) =>{
    dispatch(applicationServicesAddToOrder({currentApplicationId,currentApplicationOrderId,data})).then((response)=>{
      if (response.payload === 200){
        const orderID = currentApplicationOrderId;
        dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
        swal("Обновлен!", {
          icon: "success",
        })
      }else{
        // ${response.payload.message.parking_has_service_id[0]}
        swal(`Услуга уже добавлена в текущий заказ`, {
          icon: "error",
        })
      }
    })
  }

  const handleDeleteService = (data) => {
    dispatch(applicationServicesDeleteOrder({currentApplicationId,currentApplicationOrderId,data})).then((response)=>{
      if (response.payload === 200){
        const orderID = currentApplicationOrderId;
        dispatch(currentApplicationServiceById({currentApplicationId,orderID}))
        swal("Удалено!", {
          icon: "success",
        })
      }else{
        swal(`${response.payload.message.parking_has_service_id[0]}`, {
          icon: "Ошибка",
        })
      }
    })
  }

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);
    const day = dateObject.getDate().toString().padStart(2, '0');
    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObject.getFullYear().toString().slice(2, 4);

    return `${day}-${month}-${year}`;
  };
  const getStatusColor = (statusCode) => {
    const lowerCaseStatus = statusCode?.toLowerCase();
    // Map status names to colors
    const colorMap = {
      cancelled: '#EB5757',
      approved: '#F2994A',
      completed: '#27AE60',
    };

    // Default to black if the status name is not in the map
    return colorMap[statusCode] || '#536E9B';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      sendMessage()
    }
  };

  //chat
  const sendMessage = () => {
    if (single.application?.chat === undefined){
      const modelID = single.application?.id
      const id = single.application?.id
      const message = messageContent
      dispatch(sendMessageAndCreateRoomChat({model, modelID, message})).then((response)=>{
        if (response.meta.requestStatus === "fulfilled"){
          const applicationId = single.application?.id
          dispatch(getMessagesFromChat({applicationId}))
          dispatch(applicationsSingle(id))
          console.log('use first APi')
        }
      })
      setMessageContent('')
    }else{
      const chatRoom= single.application.chat.chat_room_id
      const content = messageContent
      dispatch(sendMessageToRoomChat({chatRoom,content})).then((response)=>{
        if (response.meta.requestStatus === "fulfilled"){
          const applicationId = single.application?.id
          dispatch(getMessagesFromChat({applicationId}))
          console.log('use second APi')
        }
      })
      setMessageContent('')
    }
  }

  const renderContent = () => {
    switch (key) {
      case 'chat' :
        return <ApplicationChat
            single={single}
            messageContent={messageContent}
        />
      case  'tabServices':
        return <AplicationServices
            single={single}
            getApplicationSelectedServices={getApplicationSelectedServices}
            getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}

        />
      case 'tabCurrentServices' :
        return <AplicationServicesItem
            currentServices={currentServices.currentService}
            role={user.role} approvedPrice={approvedPrice}
            setApprovedPrice={setApprovedPrice}
            toggleCompleteApplicationService={toggleCompleteApplicationService}
            setOrderServices={setOrderServices}
            orderServices={orderServices}
            handleDeleteService={handleDeleteService}
            services={currentServices}
            setKey={setKey}

        />
      case "tab1":
        return (
            <ApplicationsTS
                single={single}
                setDataQuickEdit={setDataQuickEdit}
                comment={single.application.car_additional}
                dataQuickEdit={dataQuickEdit}
            />
        );
      case "tab2":
        return <ApplicationsPhoto single={single} />;
      case "tab3":
        return <ApplicationsDocument single={single} />;
      case 'application-logs' :
        return <ApplicationLogs logs={single.logs} />
      case 'serviceList' :
        return <ApplicationServiceList
            openCurrentOrders={openCurrentOrders}
            formatDate={formatDate}
            getStatusColor={getStatusColor}
            handleSelect={handleSelect}
        />
      default:
        return null;
    }
  };
  return (
      <>
        <MyModal isOpen={isModalOpen} onClose={closeModal} ariaHideApp={false} >

          {single.length !== 0 ? (
            <div className="ov-test lllkkk" >
              <div className="car-row__item d-flex">
                {/* Слайдер изображений */}
                <ApplicationSliderImage
                  item={single.application}
                  setFavorite={setFavorite}
                />
                {/*  */}
                {/* Синий информационный блок  */}
                <ApplicationModalHeader single={single} setKey={setKey} />
                {/*  */}
              </div>
              <div className="modal-block__body" style={{minHeight:'35vh'}}>
                <div className="modal-block__sidebar sidebar-column">
                  <Tabs activeKey={key} onSelect={handleSelect}>
                    <Tab  key={'desc'} eventKey="tab1" title="Описание ТС" />
                    {/*<Tab key={'photos'} eventKey="tab2" title="Фотографии" />*/}
                    {/*<Tab key={'docs'} eventKey="tab3" title="Документы" />*/}
                    <Tab key={'chat'} eventKey="chat" title="Чат" />
                    <Tab key={'application-logs'} eventKey="application-logs" title="Логи" />
                    {/*{user.role === 'Partner' || user.role === 'Manager' ?*/}
                    <Tab key={'service'} eventKey="tabServices" title="Заказать услугу" />
                     {/*: '' }*/}
                    <Tab key={'sidebar'} title={<a href="#" className={'mb-0'} >Мои заказы</a>} eventKey="openSideBar"/>
                    {/*{showSubOrders && (*/}
                    {/*    currentServices.services.orders?.slice().reverse().map((item, index)=> {*/}
                    {/*      return (*/}
                    {/*          <Tab*/}
                    {/*              eventKey="tabCurrentServices"*/}
                    {/*              key={index}*/}
                    {/*               title={*/}
                    {/*                 <a href="#"className={'mb-0 ml-2'} onClick={(e) => {*/}
                    {/*                   openCurrentOrders(item.id, item.application_id)}}>*/}
                    {/*                   <span style={{ fontSize: '14px' }}>{`Заказ № ${item.id}`}</span>*/}
                    {/*                   <span style={{ fontSize: '12px', color: 'gray' }}>{` от ${formatDate(item.created_at)}`}</span>*/}
                    {/*                   <br/>*/}
                    {/*                   <span*/}
                    {/*                       style={{*/}
                    {/*                         fontSize: '11px',*/}
                    {/*                         color: getStatusColor(item.status_code),*/}
                    {/*                         textAlign: 'left',*/}
                    {/*                         display:"block"*/}
                    {/*                       }}>{item.status_name}</span>*/}
                    {/*                 </a>*/}
                    {/*               }*/}
                    {/*          />*/}
                    {/*      )*/}

                    {/*    })*/}
                    {/*)}*/}
                  </Tabs>
                </div>
                <div id={'modal-block__main'} className="modal-block__main modal-block__scroll" ref={contentRef}>
                  {renderContent()}
                </div>
              </div>
              {!showFooterForOrders && !showFooterForChat ?
                <div className="modal-block__footer d-flex justify-content-between align-items-center">
                <div>
                  <ApplicationsFooterPhotoDownload single={single} />
                  {/* Скачать акт */}
                  <ApplicationGenereteAct
                    item={single.application}
                    role={user.role}
                    text="Скачать акт"
                  />
                  {/*  */}

                  {/* Принять и отклонить */}
                  {user.role !== 'Partner' ?
                      <ApplicationAcceptBtn
                          item={single.application}
                          role={user.role}
                      /> : ''
                  }

                  <ApplicationFooterDeny
                    item={single.application}
                    role={user.role}
                  />
                  {/*  */}

                  {/* Отображение кнопки редактирование  */}
                  <span className="mr-2 ml-2">
                    <ApplicationsEditButton
                      item={single.application}
                      role={user.role}
                    />
                  </span>
                  {/*  */}
                  {/*  Удалить */}
                  <span className="mr-2 ml-2">
                    <ApplicationDeleteButton
                      item={single.application}
                      role={user.role}
                    />
                  </span>
                  {/*  */}
                </div>
                  {/* select для модерации появляется только у модератора */}
                  <ApplicaitonFooterModerator single={single} />
                  {/*  */}

                  {/* Заявка на выдачу */}
                  <ApplicationFooterIssueBtn item={single.application} />
                  {/*  */}
              </div>
                  : !showFooterForChat ?  <ApplicationFooterService
                  item={single.application}
                  role={user.role}
                  sendApplicationSelectedServices={sendApplicationSelectedServices}
                  servicesForFooter={servicesForFooter}
                  k={key}
                  cancelServiceById={cancelServiceById}
                  services={currentServices}
                  approveServiceByManager={approveServiceByManager}
                  approveServiceByPartner={approveServiceByPartner}
                  openServicesModal={openServicesModal}
                  sendOrderToWork={sendOrderToWork}
                  sendOrderToWorkFromCreatingOrder={sendOrderToWorkFromCreatingOrder}
                /> : <ApplicationFooterChat
                      setMessageContent={setMessageContent}
                      messageContent={messageContent}
                      sendMessage={sendMessage}
                      handleKeyPress={handleKeyPress}
                  />
              }
            </div>
          ) : (
            ""
          )}
        </MyModal>
        <ApplicationItemServicesModal
            servicesToSelect={single?.parkingHasServices}
            isServiceModalOpen={isServiceModalOpen}
            closeServicesModal={closeServicesModal}
            handleAddServiceToOrder={handleAddServiceToOrder}
        />
      </>
  );
}

export default ApplicationItemModal;
