import React, {useEffect, useState} from 'react';

import {limitToLast, onValue, ref} from "firebase/database";
import {db} from "../../../../../../firebase";
import {useSelector} from "react-redux";

function AplicationServices(
    {
        single,
        getApplicationSelectedServices,
        getSelectedServicesForFooterInfo,
    }) {
    const { user } = useSelector((state) => state.auth);

    const [selectedServices, setSelectedServices] = useState([]);
    const [parkingId, setParkingId] = useState(null);


    // Function to handle checkbox click
    const collectDataAboutSelectedServices = (e, parkingId, serviceId, cost,cost_for_us, name) => {
        setParkingId(parkingId);
        setSelectedServices((prevServices) => {
            if (e.target.checked) {
                return [
                    ...prevServices,
                    {
                        parking_has_service_id: serviceId,
                        price: cost,
                        price_for_us:cost_for_us,
                        name:name,
                    },
                ];
            } else {
                return prevServices.filter(
                    (service) => service.parking_has_service_id !== serviceId
                );
            }
        });
    };

    useEffect(()=>{
        getApplicationSelectedServices(single.application.id, parkingId,selectedServices)
    },[selectedServices])

    useEffect(()=>{
        getSelectedServicesForFooterInfo(selectedServices)
    },[getApplicationSelectedServices])
    const [groupedData, setGroupedData] = useState({});

    useEffect(() => {
        const grouped = {};
        single.parkingHasServices?.forEach(item => {
            const categoryId = item.parking_service.service_category?.id;
            if (!grouped[categoryId]) {
                grouped[categoryId] = [];
            }
            grouped[categoryId].push(item);
        });

        setGroupedData(grouped);
    }, [single.parkingHasServices]);

    return (
       <>
           <div className="tab-content" id="v-pills-tabContent">
               <div className="tab-pane fade show active mlr-20 table-data" id="v-pills-tab10" role="taborderspanel"
                    aria-labelledby="v-pills-tab10-tab">
                   {Object.keys(groupedData).map(categoryId => {
                       const categoryName = groupedData[categoryId][0].parking_service.service_category?.name;
                       return (
                           <table className="price-list-table table-services">
                               <thead>
                                   <tr>
                                       <th style={{minWidth:'170px'}} ></th>
                                       <th
                                           style={{
                                               display:'flex',
                                               justifyContent: 'center'
                                           }}
                                           className="price-list-head"><b>{categoryName }</b></th>
                                   </tr>
                               </thead>
                               <tbody>
                                   <tr>
                                   <td>

                                   </td>
                                       {user.role !== 'Partner' &&
                                           <td className="text-center d-flex justify-content-between">
                                               <span>Заказчику</span>
                                               <span>Наше</span>
                                           </td>
                                       }
                                   <td>

                                   </td>
                               </tr>
                               {groupedData[categoryId].map(item => (
                                   <tr key={item.id }>
                                       <td>
                                           <div className="table-service-title">{item.parking_service.name}</div>
                                           {/*<span className="text11pxnormalblue d-block">Дарья +7 (495) 128-50-80</span>*/}
                                       </td>
                                       <td className={`text-center  ${user.role !== 'Partner' ? 'd-flex justify-content-between' : ''}`}>
                                           <span>
                                               {item.cost ? `${item.cost}` : 'не указано'}

                                           </span>
                                           {user.role !== 'Partner' &&
                                               <span>
                                                    {item.cost_for_us ? `${item.cost_for_us}` : 'не указано'}
                                               </span>
                                           }
                                       </td>
                                       <td className="text-right">
                                           <label className="switch-radio-wrap d-flex flex-row-reverse">
                                               <input
                                                   type="checkbox"
                                                   onClick={(e)=>{
                                                       collectDataAboutSelectedServices(
                                                           e,
                                                           item.parking_id,
                                                           item.id,
                                                           item.cost,
                                                           item.cost_for_us,
                                                           item.parking_service.name
                                                       )}}
                                               />
                                                   <span className="switcher-radio ml-2 mr-0"></span>
                                                   <span  className="condition">Добавить</span>
                                           </label>
                                       </td>
                                   </tr>
                               ))}
                               </tbody>
                           </table>
                       )
                   })}
               </div>
           </div>
       </>
    );
}

export default AplicationServices;