import React from "react";
import ApplicationsItemSystemData from "../applications-item-system-data/applications-item-system-data";
import ApplicationsAdministrativeData from "../applications-administrative-data/applications-administrative-data";
import ApplicationsComment from "../applications-comment/applications-comment";
import ApplicationAboutCar from "../applications-about-car/applications-about-car";
import ApplicationsThoseState from "../applications-those-state/applications-those-state";
import { appQuickEdit } from "../../../../../../store/applications/application-modal-quick-edit/applicationModalQuickEditSlice";
import { applicationsSingle } from "../../../../../../store/applications/applications-single/applicationsSingleSlice";
import {useDispatch, useSelector} from "react-redux";
import swal from "sweetalert";
import { useState } from "react";
import ApplicationsPhoto from "../applications-photo/applications-photo";
import ApplicationsDocument from "../applications-document/applications-document";

function ApplicationsTS({ single, setDataQuickEdit, dataQuickEdit, comment }) {
  const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
  const [quickEdit, setQuickEdit] = useState(false);
  const handleClick = () => {
    dispatch(appQuickEdit(dataQuickEdit)).then((response) => {
      if (response.payload.status === 200) {
        swal("Успешно обновлено!", {
          icon: "success",
        });
        dispatch(applicationsSingle(single.application?.id));
      } else {
        let errorMessage = "Ошибка при создании!";

        if (response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  return (
    <div className="application-modal">
      {/* Админ. данные */}
      <span className="application-modal__title">Админ. данные</span>
      <ApplicationsAdministrativeData single={single} />
      <hr />
      {/*  */}

      {/* Комментарии*/}
      <span className="application-modal__title">Комментарии</span>
      <ApplicationsComment
        comment={single.application.car_additional}
        quickEdit={quickEdit}
        setDataQuickEdit={setDataQuickEdit}
        dataQuickEdit={dataQuickEdit}
      />
      <hr />
      {/*  */}

      {/* Системные данные */}
      <span className="application-modal__title">
        Системные данные /
        <span className="btn-systemic cursor-pointer">
          {quickEdit ? (
            <span>
             / Описание ТС
                {user.role !== 'Partner' ?
                    <svg
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ml-2"
                        onClick={(e) => {
                            setQuickEdit(!quickEdit);
                            handleClick();
                        }}
                    >
                        <path
                            d="M0.25 1.75C0.25 0.921573 0.921573 0.25 1.75 0.25H4.75H9.25H10.4393C10.8372 0.25 11.2187 0.408035 11.5 0.68934L13.5303 2.71967C13.671 2.86032 13.75 3.05109 13.75 3.25V12.25C13.75 13.0784 13.0784 13.75 12.25 13.75H9.25H4.75H1.75C0.921573 13.75 0.25 13.0784 0.25 12.25V1.75ZM4.75 12.25H9.25V7.75H4.75V12.25ZM10.75 12.25H12.25V3.56066L10.75 2.06066V3.25C10.75 4.07843 10.0784 4.75 9.25 4.75H4.75C3.92157 4.75 3.25 4.07843 3.25 3.25V1.75H1.75V12.25H3.25V7.75C3.25 6.92157 3.92157 6.25 4.75 6.25H9.25C10.0784 6.25 10.75 6.92157 10.75 7.75V12.25ZM4.75 1.75V3.25H9.25V1.75H4.75Z"
                            fill="#536E9B"
                        ></path>
                    </svg> : ''
                }

            </span>
          ) : (
            <span className="edit-systemic">
                 Описание ТС
                {user.role !== 'Partner' ?
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-2"
                onClick={(e) => {
                  setQuickEdit(!quickEdit);
                }}
              >
                <path
                  d="M12.2197 1.71967C12.5126 1.42678 12.9874 1.42678 13.2803 1.71967L16.2803 4.71967C16.5732 5.01256 16.5732 5.48744 16.2803 5.78033L6.53033 15.5303C6.38968 15.671 6.19891 15.75 6 15.75H3C2.58579 15.75 2.25 15.4142 2.25 15V12C2.25 11.8011 2.32902 11.6103 2.46967 11.4697L9.96951 3.96983L12.2197 1.71967ZM10.5 5.56066L3.75 12.3107V14.25H5.68934L12.4393 7.5L10.5 5.56066ZM13.5 6.43934L14.6893 5.25L12.75 3.31066L11.5607 4.5L13.5 6.43934Z"
                  fill="#536E9B"
                ></path>
              </svg> : ''
                }
            </span>
          )}
        </span>
      </span>
      <ApplicationsItemSystemData
        single={single}
        quickEdit={quickEdit}
        setDataQuickEdit={setDataQuickEdit}
        dataQuickEdit={dataQuickEdit}
      />
      <hr />
      {/*  */}

      {/* Об автомобиле*/}
      <span className="application-modal__title">Об автомобиле</span>
      <ApplicationAboutCar single={single} />
      <hr />
      {/*  */}

      {/* Тех. состояние*/}
      <span className="application-modal__title">Тех. состояние</span>
      <ApplicationsThoseState single={single.application} />
      <hr />
      {/*  */}

    {/*    DOCUMENTS */}
        <span className="application-modal__title">Документы</span>
        <ApplicationsDocument single={single} />
        <hr />
    {/*    PHOTO */}
        <span className="application-modal__title">Фото</span>
        <ApplicationsPhoto single={single} />
    </div>
  );
}

export default ApplicationsTS;
