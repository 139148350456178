import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  editParkingReset,
  editParkings,
  parkingsEditPut,
} from "../../../store/parkings/parkings-edit-slice/parkingsEditSlice";
import { selectArray } from "../../../utils/select-array";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import ParkingEditApplication
  from "../../../components/parkings/parking-edit/parking-edit-application/parking-edit-application";
import ParkingServive from "../../../components/parkings/parkings-create-update/parking-service/parking-sevice";
import {Tab, Tabs} from "react-bootstrap";
import ParkingEditPrices from "../../../components/parkings/parking-edit/parking-edit-prices/parking-edit-prices";
import {editParkingPrice,updateParkingPrices} from "../../../store/parkings/parking-edit-prices-slice/parkingEditPricesSlice";

import ParkingEditService from "../../../components/parkings/parking-edit/parking-edit-service/parking-edit-service";
import {
  editParkingService,
  updateParkingService
} from "../../../store/parkings/parking-edit-services-slice/parkingEditServicesSlice";
function ParkingEditPage() {
  const { parkingsEdit } = useSelector((state) => state.parkingsEdit);
  const parkingPrice = useSelector((state)=> state.parkingPrices.parkingPrice)
  const parkingServices = useSelector((state)=> state.parkingServices.parkingServices)

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [key, setKey] = useState("parking-tab-1");

  const parkingArray = parkingsEdit?.parking?.legals.map((item) => item.id);
  const managersArray = parkingsEdit?.parking?.managers.map((item) => item.id);

  const token = localStorage.getItem("attachment_token");

  const [parkingEditForm, setParkingEditForm] = useState({
    _token : '',
    title: "",
    address: "",
    code: "",
    status: 0,
    legals: [],
    users: [],
    contractors: [],
    coordinates: "",
    map_url: "",
    working_hours: "",
    nav_to_trans: "",
    qty_parking: "",
    parking_surface: "",
    covered_parking: 0,
    heated_parking: 0,
    fencing: null,
    cctv: 0,
    client_zones: 0,
    parking_fences : [],
    parking_surfaces : []
  });


  // пользователи доступные у данной парковки
  const [parkingPartners, setParkingPartners] = useState([])
  const [parkingPricesEdit, setParkingPricesEdit] = useState([
    {
      id: 0,
      discount_price: 0,
      regular_price: 0,
      free_days: 0,
      car_type_id: 1,
      car_type_name: "Легковые",
    },
  ],)

  const [parkingServicesEdit, setParkingServicesEdit] = useState([] )


  const [selectedOption, setSelectedOption] = useState(0);
  const [inputDisable, setInputDisable] = useState(false);
  localStorage.removeItem("attachment_token_parking");
  useEffect(() => {
    setParkingEditForm({
      _token : token?.length !==0 ? token : '',
      title: parkingsEdit.length !== 0 ? parkingsEdit.parking.title : "",
      address: parkingsEdit.length !== 0 ? parkingsEdit.parking.address : "",
      code: parkingsEdit.length !== 0 ? parkingsEdit.parking.code : "",
      status: parkingsEdit.length !== 0 ? parkingsEdit.parking.status : "",
      legals: parkingsEdit.length !== 0 ? parkingArray : [],
      users: parkingsEdit.length !== 0 ? managersArray : [],

      coordinates: parkingsEdit.length !== 0 ? parkingsEdit.parking.coordinates : "",
      map_url: parkingsEdit.length !== 0 ? parkingsEdit.parking.map_url : "",
      working_hours: parkingsEdit.length !== 0 ? parkingsEdit.parking.working_hours : "",
      nav_to_trans: parkingsEdit.length !== 0 ? parkingsEdit.parking.nav_to_trans : "",
      qty_parking: parkingsEdit.length !== 0 ? parkingsEdit.parking.qty_parking : "",
      covered_parking: parkingsEdit.length !== 0 ? parkingsEdit.parking.covered_parking : "",
      heated_parking: parkingsEdit.length !== 0 ? parkingsEdit.parking.heated_parking : "",
      fencing: parkingsEdit.length !== 0 ? parkingsEdit.parking.fencing : "",
      cctv: parkingsEdit.length !== 0 ? parkingsEdit.parking.cctv : "",
      client_zones: parkingsEdit.length !== 0 ? parkingsEdit.parking.client_zones : "",
      parking_fences: parkingsEdit.length !== 0 ? parkingsEdit.parking_fences : [],
      parking_surfaces: parkingsEdit.length !== 0 ? parkingsEdit.parking_surfaces : [],
    });
    dispatch(editParkingPrice({id:parkingsEdit?.parking?.id, partner_id: selectedOption }))

  }, [parkingsEdit]);

  useEffect(() => {
    dispatch(editParkingReset());
    dispatch(editParkings(id));
  }, [dispatch]);
  useEffect(() => {
    const updatedPrices = parkingPrice?.prices?.map(price => ({
      ...price,
      parking_id: parkingsEdit?.parking?.id,
    }));
    setParkingPricesEdit(updatedPrices)
    setParkingPartners(parkingServices?.partners)
  }, [parkingPrice]);

  useEffect(()=>{
    dispatch(editParkingService({id: id, partner_id: 0}))
  },[])

  useEffect(()=>{
    setParkingServicesEdit(parkingServices?.parking_services)
  },[parkingServices])

  // Собираем данные с полей и записываем в STATE
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setParkingEditForm({ ...parkingEditForm, [name]: newValue });

  };

  const handlePriceChange = (e, id) =>{
    const { name, value } = e.target;
    setParkingPricesEdit((prevItems) =>
        prevItems.map((item) =>
            item.id === id ? { ...item, [name]: parseInt(value)  } : item
        )
    );
  }
  const handleServiceChange = (e, id, cat_name) =>{
    const { name, value, type, checked } = e.target;
    let newValue = value;
    if (name === 'cost'){
      newValue = value
    }
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }

    setParkingServicesEdit(prevItems =>
        prevItems.map(category =>
            category.category === cat_name ? {
              ...category,
              services: category.services.map(service =>
                  service.parking_service_id === id ? { ...service, [name]: newValue } : service
              )
            } : category
        )
    );
  }



  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    // Set the selected option in the state

    setSelectedOption(selectedId);
    if (key === 'parking-tab-2') {
      dispatch(editParkingPrice({id: parkingsEdit?.parking?.id, partner_id: selectedId})).then(response => {
        const responsePrices = response.payload?.prices
        setParkingPricesEdit(responsePrices)
      })
    }
    if (key === 'parking-tab-3'){
      if (+selectedId !== 0 ){
        setInputDisable(true)
      }else{
        setInputDisable(false)
      }

      dispatch(editParkingService({id:parkingsEdit?.parking?.id,partner_id: selectedId})).then((resp)=>{

        setParkingServicesEdit(resp.payload?.parking_services)
      })
    }
  };
  const handleChangeLegals = (e) => {
    const arrayLegals = e;
    const arrayValue = [];
    arrayLegals.map((item) => {
      arrayValue.push(item.value);
    });

    setParkingEditForm({ ...parkingEditForm, legals: arrayValue });
  };

  const handleChangeUsers = (e, name) => {
    const arrayUsers = e;
    const arrayValue = [];
    arrayUsers.map((item) => {
      arrayValue.push(item.value);
    });
    setParkingEditForm({ ...parkingEditForm, [name]: arrayValue });
  };

  const convertToNewFormat = (data, partner_id) => {

    const parking_services = data.flatMap(category =>
        category.services.map(service => ({
          parking_service_id: service.parking_service_id,
          is_active: service.is_active,
          cost: service.cost,
          cost_for_us: +partner_id === 0 ? service.cost_for_us : null,
          contact_name: service.contact_name,
          contact_phone: service.contact_phone,
          comment: service.comment ? service.comment : ''
        }))
    );

    // return { partner_id, parking_services };
    return { parking_services };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newParkingPricesToUpdate = parkingPricesEdit.map(({ id, car_type_name,created_at,parking_id,updated_at, partner_id, ...rest }) => rest);
    // const newParkingServiceToUpdate = parkingServicesEdit.map(() => {
    //
    // });
    const newData = convertToNewFormat(parkingServicesEdit,selectedOption );
    // console.log(newData)
    // const newParckingServisecWIthoutNull = newParkingServiceToUpdate.map(obj => ({
    //   ...obj,
    //   cost: obj.cost === null ? '' : obj.cost,
    //   contact_name: obj.contact_name === null ? '' : obj.contact_name,
    //   contact_phone: obj.contact_phone === null ? '' : obj.contact_phone,
    //   comment: obj.comment === null ? '' : obj.comment,
    // }));

    swal({
      title: "Вы уверены что хотите обновить данные?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete && key === 'parking-tab-1') {
        dispatch(parkingsEditPut({ id: id, data: parkingEditForm })).then(
          (response) => {
            if (response.payload.status === 200) {
              swal("Стоянка успешна обновлена!", {
                icon: "success",
              }).then(() => {
                dispatch(editParkings(id));
                navigate("/parkings");
              });
            } else {
              let errorMessage = "Ошибка при редактирование стоянки!";
              if (response.payload.data && response.payload.data.errors) {
                const errorMessages = Object.values(
                  response.payload.data.errors
                ).reduce((acc, val) => acc.concat(val), []);
                errorMessage = errorMessages.join("\n");
              }
              swal(errorMessage, {
                icon: "error",
              });
            }
          }
        );
      }
      if (willDelete && key === 'parking-tab-2'){
          dispatch(updateParkingPrices({id:id, pricings:newParkingPricesToUpdate, partner_id : selectedOption})).then(
              (response)=>{
                if (response.payload === 200){
                  swal("Цены успешно обновлены!", {
                    icon: "success",
                  }).then(() => {
                    dispatch(editParkingPrice(id));
                    navigate("/parkings");
                  });
                }else {
                  let errorMessage = "Ошибка при редактирование цен!";
                  if (response.payload.data && response.payload.data.errors) {
                    const errorMessages = Object.values(
                        response.payload.data.errors
                    ).reduce((acc, val) => acc.concat(val), []);
                    errorMessage = errorMessages.join("\n");
                  }
                  swal(errorMessage, {
                    icon: "error",
                  });
                }
              }
          )
      }
      if (willDelete && key === 'parking-tab-3') {
        // console.log(newData)
        dispatch(updateParkingService({id: id, newData,selectedOption})).then(
            (response)=>{
              if (response.payload === 200){
                swal("Услуги успешно обновлены!", {
                  icon: "success",
                }).then(() => {});
              }else {
                let errorMessage = "Ошибка при редактирование услуг!";
                if (response.payload.data && response.payload.data.errors) {
                  const errorMessages = Object.values(
                      response.payload.data.errors
                  ).reduce((acc, val) => acc.concat(val), []);
                  errorMessage = errorMessages.join("\n");
                }
                swal(errorMessage, {
                  icon: "error",
                });
              }
            }
        )
      }
    });
  };

  // Юридические лица по умолчанию и все юридические лица
  const defaultIndividualOptions = selectArray(
    parkingsEdit?.parking?.legals,
    "legals"
  );

  const individualsOptions = selectArray(parkingsEdit?.legals, "legals");
  //

  // Пользователи по умолчанию и все пользователи
  const usersSelectedOptions = selectArray(
    parkingsEdit?.parking?.managers,
    "legals"
  );
  const usersOptions = selectArray(parkingsEdit.users, "users");
  //

  // Констракттор по умолчанию и все констракторы

  // Выбранные Constractor по умоланию
  const contractorsSelectParking =
    parkingsEdit?.contractors &&
    parkingsEdit?.contractors.filter((item) => item.selected === true);

  const constractorsSelectedOptions = selectArray(
    contractorsSelectParking,
    "contractors"
  );

  //
  const constractorsOptions = selectArray(
    parkingsEdit.contractors,
    "contractors"
  );

  const renderContent = () => {
    switch (key) {
      case "parking-tab-1":
        return (
            parkingsEdit.length !== 0 && ( <ParkingEditApplication
                      key={parkingsEdit.parking?.id}
                      handleSubmit={handleSubmit}
                      parkingsEdit={parkingsEdit}
                      handleChange={handleChange}
                      defaultIndividualOptions={defaultIndividualOptions}
                      individualsOptions={individualsOptions}
                      handleChangeLegals={handleChangeLegals}
                      usersSelectedOptions={usersSelectedOptions}
                      handleChangeUsers={handleChangeUsers}
                      usersOptions={usersOptions}
                      constractorsSelectedOptions={constractorsSelectedOptions}
                      constractorsOptions={constractorsOptions}
                  />
              )
        );
      case "parking-tab-2":
        return  (
            parkingPrice?.length !== 0 && (<ParkingEditPrices
                parkingPrice={parkingPricesEdit}
                parkingPartners={parkingPartners}
                handlePriceChange={handlePriceChange}
                handleSelectChange={handleSelectChange}
                selectedOption={selectedOption}
            />)
        );
      case "parking-tab-3":
        return (parkingServices?.length !== 0 && (<ParkingEditService
                  parkingServicesEdit={parkingServicesEdit}
                  handleServiceChange={handleServiceChange}
                  handleSelectChange={handleSelectChange}
                  inputDisable={inputDisable}
                  parkingPartners={parkingPartners}
                  selectedOption={selectedOption}
              />)
        );
      default:
        return null;
    }
  };
  const handleSelect = (k) => {
    setKey(k);
  };
  const handelNextBtn = () =>{
    switch (key){
      case "parking-tab-1":
        setKey('parking-tab-2');
        break;
      case "parking-tab-2":
        setKey('parking-tab-3');
        break;
      case "parking-tab-3":
        setKey('parking-tab-1');
        break;
    }
  }

  return (
      <form onSubmit={handleSubmit}>
        <div className="container page-head-wrap">
          <div className="page-head">
            <div className="page-head__top d-flex align-items-center">
              <h1>{parkingsEdit.title}</h1>
              <div className="ml-auto d-flex">
                <button
                    type="submit"
                    className="btn btn-white"
                    // onClick={handelNextBtn}
                >
                  Сохранить
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="inner-page">
            <div className="row no-gutters position-relative">
              <div className="col-md-8 block-parking">
                <div
                    className="nav block-nav__item "
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                >
                  <Tabs activeKey={key} onSelect={handleSelect}>
                    <Tab eventKey="parking-tab-1" title="Заявка" />
                    {/*<Tab eventKey="parking-tab-2" title="Прайс-лист" />*/}
                    <Tab eventKey="parking-tab-3" title="Услуги" />
                  </Tabs>
                </div>
              </div>
              <div className="tab-content tab-content-main col-md-12">
                {renderContent()}
              </div>
            </div>
          </div>
        </div>
      </form>
  );
}

export default ParkingEditPage;
