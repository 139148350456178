import React, {useEffect, useState} from 'react';
import {db} from "../../../../../../firebase";
import { ref, onValue } from 'firebase/database';
import {useDispatch, useSelector} from "react-redux";
import {applicationServicesGetById} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";

function ApplicationServiceList({openCurrentOrders, formatDate, getStatusColor, handleSelect}) {
    const [filteredServices, setFilteredServices] = useState([])
    const [activeTab, setActiveTab] = useState('all')
    const [ordersFromFireBase, setOrdersFromFireBase] = useState({});
    const [error, setError] = useState(null);
    const { single } = useSelector((state) => state.applicationSingle);
    const services  = useSelector((state) => state.applicationService.services.orders);
    const dispatch = useDispatch();

    // firebase
    useEffect(() => {
        const ordersRef = ref(db, `myparkingdev/orders-in-applications/${single.application?.id}`);
        const unsubscribe = onValue(ordersRef, (snapshot) => {
            if (!snapshot.val()){
                return
            }
            else {
                const newOrder = snapshot.val().order;
                setOrdersFromFireBase(snapshot.val());
                setError(null);
                setFilteredServices((prevOrders) => {
                    const orderExists = prevOrders.some(order => order?.id === newOrder?.id);

                    if (orderExists) {
                        return prevOrders.map(order =>
                            order?.id === newOrder?.id ? { ...order, ...newOrder } : order
                        );
                    } else {
                        // Add the new order
                        return [...prevOrders, newOrder];
                    }
                });
            }
        }, (error) => {
            setError(error.message);
        });
        return () => {
            unsubscribe();
        };
    }, []);


    const servicesFilter = (filterBy) => {
        switch (filterBy){
            case 'all':
                setActiveTab(filterBy)
                setFilteredServices(services)
                break;
            case 'toPartner':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'toPartner' || item.status_code === 'toManager'))
                break;
            case 'completed':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'completed' ))
                break;
            case 'in-progress':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'in-progress' || item.status_code === 'partly-completed' ))
                break;
            case 'cancelled':
                setActiveTab(filterBy)
                setFilteredServices(services.filter(item => item.status_code === 'cancelled'))
                break;
            default:
                setFilteredServices(services)
        }
    }

    useEffect(() => {
        const currentApplicationId = single.application.id
        dispatch(applicationServicesGetById({currentApplicationId})).then((response)=>{
        })
    }, [])
    useEffect(() => {
        setFilteredServices(services)
    }, [services])

    return (
        <div className={'order__list row d-flex'}>
            <h5 className={'text-center mb-3'}>Заказы</h5>
            <div className={'order__list-filters row mb-4 cursor-pointer'} >
                <div
                    className={`custom-w-20 ${activeTab === 'all' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('all')}}
                >
                    <span >Все({services.length}) </span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'toPartner' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('toPartner')}}
                >
                    <span>На согласов</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'in-progress' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('in-progress')}}
                >
                    <span>В работе</span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'completed' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('completed')}}
                >
                    <span>Готово </span>
                </div>
                <div
                    className={`custom-w-20 ${activeTab === 'cancelled' ? 'active' : ''}`}
                    onClick={()=>{servicesFilter('cancelled')}}
                >
                    <span>Отменено</span>
                </div>
            </div>
            {filteredServices && filteredServices[0] !==0 && filteredServices?.length != 0 ? filteredServices?.slice().reverse().map((item, index) => {
                return (
                    <a href="#" key={item?.id} className={'mb-0 col-md-6'} onClick={(e) => {
                        openCurrentOrders(item.id, item.application_id)
                        handleSelect('tabCurrentServices')
                    }}>
                        <span
                            style={{fontSize: '14px'}}
                        >
                            {`Заказ № ${item?.id}`}
                        </span>
                        <span
                            style={{fontSize: '12px', color: 'gray'}}
                        >
                            {` от ${formatDate(item.created_at)}`}
                        </span>
                        <br/>
                        <span
                            style={{
                            fontSize: '11px',
                            color: getStatusColor(item.status_code),
                            textAlign: 'left',
                            display: "block"
                            }}
                        >
                            {item.status_name}
                        </span>
                    </a>
                )
                })
                :
                    <div className={'col-md-12 opacity-25'}>
                        <b>к сожалению тут пусто ...</b>
                    </div>
            }
        </div>


    )
}

export default ApplicationServiceList;