import axios from "axios";
import data from "bootstrap/js/src/dom/data";

// PARKINGS All LiST
let API_URL = "/v1/parkings";
let API_COVERAGE = '/v1/parking-surfaces'
let API_FENCE = '/v1/parking-fences'
const API_SERVICES_CATEGORY = '/v1/service-categories'
const API_SERVICES = '/v1/parkingservices'

// Список PARKINGS/
const parkigns = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
//Получить парковку по id

const getParkingById = async (id) => {
    const response = await axios.get(API_URL + "/" + id);
    return response.data;
};

// Редактирование данных parkign
const parkignEditData = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/edit/details");
    // console.log(response.data)
  return response.data;
};

const parkingEditPrices = async (id,partner_id) => {
    let urlToGetRightPrices = partner_id === 0 ? "/edit/prices" : `/edit/prices?partner_id=${partner_id}`
    const response =  await axios.get(API_URL + "/" + id + urlToGetRightPrices);
    return response.data;
};

const parkingEditService = async ({id, partner_id}) => {
    let urlToGetRightServices = partner_id === 0 ? "/edit/parkingservices" : `/edit/parkingservices?partner_id=${partner_id}`
    const response =  await axios.get(API_URL + "/" + id + urlToGetRightServices);
    return response.data;
};

const parkingUpdateService = async ({id, newData, selectedOption}) => {
    const object = {
        parking_services: newData.parking_services
    }
    if (+selectedOption !== 0){
        object.partner_id = +selectedOption
    }
    console.log(object)
    const response = await axios.put(API_URL + "/" + id + "/update/parkingservices", object);
    return response.status;
};
const parkingUpdatePrices = async (id,pricings, partner_id) => {
    const response = await axios.put(API_URL + "/" + id + "/update/prices", {pricings,partner_id});
    return response.status;
};

const parkignEditPut = async (id, data) => {
  const response = await axios.put(API_URL + "/" + id + '/update/details', data);
  return { data: response.data, status: response.status };
};
//

// Удаление parkign
const parkignDelete = async (id) => {
  const response = await axios.delete(API_URL + "/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//

// Создание parkign
const parkignsCreate = async () => {
  const response = await axios.get(API_URL + "/create");
  return response.data;
};

const parkignsCreateData = async (data) => {
  const response = await axios.post(API_URL, data);
  return response.status;
};
//
const coverageCreate = async (name)=>{
    let data = {'name' : name}
    const response = await axios.post(API_COVERAGE, data);
    return {'response' : response.data, 'status' : response.status};
}
// get all coverage
const getAllCoverage = async ()=>{
    const response = await axios.get(API_COVERAGE);
    return response.data.parking_surfaces;
}
// delete coverage
const deleteCoverage = async (id)=>{
    const response = await axios.delete(API_COVERAGE+`/${id}`);
    return response.status;
}
// edit fence
const coverageEdit = async ({id, name})=>{
    let data = {'id' : id, 'name' : name}
    const response = await axios.put(API_COVERAGE+`/${id}`, data);
    return response.status;
}

const fenceCreate = async (name)=>{
    let data = {'name' : name}
    const response = await axios.post(API_FENCE, data);
    return {'response' : response.data, 'status' : response.status};
}
// get all fence
const getAllFence = async ()=>{
    const response = await axios.get(API_FENCE);

    return response.data.parking_fences;
}
const getFenceById = async (id)=>{
    const response = await axios.get(API_FENCE + `/${id}`);
    return response.data.parking_fences;
}
// delete fence
const deleteFence = async (id)=>{
    const response = await axios.delete(API_FENCE+`/${id}`);
    return response.status;
}
// edit fence
const fenceEdit = async ({id, name})=>{
    let data = {'id' : id, 'name' : name}
    const response = await axios.put(API_FENCE+`/${id}`, data);
    return response.status;
}
const getAllParkingServiceCategories = async ()=>{
    const response = await axios.get(API_SERVICES_CATEGORY);
    return response.data.service_categories;
}

const getAllParkingServices = async ()=>{
    const response = await axios.get(API_SERVICES);
    return {parkingServices : response.data.parking_services, carTypes : response.data.car_types};
}

const parkingServiceCategoryEdit = async ({id, name})=>{
    let data = {'id' : id, 'name' : name}
    const response = await axios.put(API_SERVICES_CATEGORY+`/${id}`, data);
    return response.status;
}

const createParkingServiceCategory = async (name)=>{
    let data = {'name' : name}
    const response = await axios.post(API_SERVICES_CATEGORY, data);
    return {'response' : response.data, 'status' : response.status};
}
// delete fence
const deleteServiceCategory = async (id)=>{
    const response = await axios.delete(API_SERVICES_CATEGORY+`/${id}`);
    return response.status;
}
// delete service
const deleteService = async (id)=>{
    const response = await axios.delete(API_SERVICES+`/${id}`);
    return response.status;
}
//add service
const createNewService = async (data)=>{
    const response = await axios.post(API_SERVICES, data);
    return {'response' : response.data, 'status' : response.status};
}
//change services sequence
const changeParkingServicesSequence = async (data)=>{
    const newData = {
        parking_services: data
    }
    const response = await axios.put(API_SERVICES+`/change-sequence`, newData);
    return response.status;
}
//change service name
const changeServiceName = async ({data, parkingservice_id})=>{
    const response = await axios.put(`${API_SERVICES}/${parkingservice_id}`, data);
    return {'response' : response.data, 'status' : response.status};
}


const parkignsService = {
    parkigns,
    getParkingById,
    parkignEditData,
    parkingEditPrices,
    parkingUpdatePrices,
    parkignEditPut,
    parkignDelete,
    parkignsCreate,
    parkignsCreateData,
    coverageCreate,
    fenceCreate,
    getAllFence,
    getFenceById,
    deleteFence,
    fenceEdit,
    getAllCoverage,
    deleteCoverage,
    coverageEdit,
    parkingEditService,
    parkingUpdateService,
    getAllParkingServiceCategories,
    getAllParkingServices,
    deleteService,
    createNewService,
    parkingServiceCategoryEdit,
    deleteServiceCategory,
    createParkingServiceCategory,
    changeParkingServicesSequence,
    changeServiceName
};

export default parkignsService;
